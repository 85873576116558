gsap.registerPlugin(ScrollTrigger);
cssanimation = (function (win) {
    var mobile_breakpoint = 768;
    var tablet_breakpoint = 1024;
    var hamburger = function () {
        var hamburger_container = document.querySelector('.hamburger');
        var upper = document.getElementsByClassName('upper');
        var middle = document.getElementsByClassName('middle');
        var lower = document.getElementsByClassName('lower');
        var hamburger_tl = gsap.timeline({
            paused: true,
            reversed: true,
            onComplete: function () {
                hamburger_container.classList.add('active');
            },
            onReverseComplete: function () {
                hamburger_container.classList.remove('active');
            }
        });
        hamburger_tl
            .to(upper, 0.5, {attr: {d: "M8,2 L2,8"}, x: 1, ease:Power2.easeInOut}, 'start')
            .to(middle, 0.5, {autoAlpha: 0}, 'start')
            .to(lower, 0.5, {attr: {d: "M8,8 L2,2"}, x: 1, ease:Power2.easeInOut}, 'start');

        if (document.getElementById('nav-primary')) {
            var menu_box = document.getElementById('nav-primary');
            var menu_tl  = gsap.timeline({paused: true, reversed: true});
            if (get_viewport_width() <= mobile_breakpoint) {
                menu_tl
                    .to (menu_box, {x: 0, ease: "power3.out"}, 'start')
                    .to ('#logo',{'opacity': 0, ease: "power3.out"}, 'start');menu_tl
            }
            else {
                menu_tl
                    .to (menu_box, {x: 0, ease: "power3.out"}, 'start')
            }
        }
        hamburger_container.addEventListener('click', function(){
            hamburger_tl.reversed() ? hamburger_tl.play() : hamburger_tl.reverse();
            menu_tl.reversed() ? menu_tl.play() : menu_tl.reverse();
        })
    }

    var paragraphs = function () {
        var text_tl = gsap.timeline();
        var paragraphs = gsap.utils.toArray('.main.content p');
        paragraphs.forEach(function (el) {
            text_reveal(el, text_tl);
        });
    }

    var text_reveal = function (el, tl) {
        tl.from (el, {
            scrollTrigger: el,
            opacity: 0,
            duration: .5,
            ease: "power4.in"
        });
    }

    var img_caption = function () {
        if (get_viewport_width() > mobile_breakpoint) {
            var offset_x = (get_viewport_width() > tablet_breakpoint)?65:-42;
            var offset_y = (get_viewport_width() > tablet_breakpoint)?80:-330;
            var caption_text = document.getElementsByClassName('wp-caption-text');
            gsap.to (caption_text,{
                scrollTrigger:{
                    trigger: caption_text.parentNode
                },
                x: offset_x,
                y: offset_y,
                duration: 1
            })
        }
    }

    var slideshow = function () {
        var slide = document.getElementsByClassName('slide');
        var slide_arr = gsap.utils.toArray(slide);
        var slide_tl = gsap.timeline();

        slide_arr.forEach(function (el, i) {
            gsap.from (el, {
                scrollTrigger: el,
                opacity: 0,
                duration: .5,
                ease: "power4.in"
            });
        });

        var slide_inner = gsap.utils.toArray('inner');
        var slide_inner_arr = gsap.utils.toArray(document.getElementsByClassName('inner'));
        slide_inner_arr.forEach(function (el, i){
            gsap.from (el, 1.5, {
                scrollTrigger: el,
                opacity: 0,
                duration: 5,
                ease: "power4.out"
            });
        });
        if (document.getElementsByClassName('main_title').length) {
            gsap.from ('.main_title', 1.5, {
                opacity: 0,
                scale: 0.5,
                duration: 1.5,
                ease: "power4.inout"
            });
        }
    }

    var shrink_header = function () {
        if (get_viewport_width() <= mobile_breakpoint) {
            var header_tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '#logo',
                    start: 'top top+=50',
                    ease: "power2",
                    toggleActions: "play none none reverse"
                }
            });
            header_tl
                .to(".monogram", {duration: .5, opacity: 0}, 0)
                .to(".acorn", {duration: .4, y: -95}, 0.2)
                .to("#content_holder", {duration: .5, y: 100}, 0)
                .to(".top_header, .brand_holder", {duration: .5, height: '140px'}, 0)
        }
    }
    var social_cameleon = function () {
        var top_header_height = document.getElementById('top_header').offsetHeight;


        var social_cameleon_tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.site_footer',
                start: 'top top+=' + top_header_height,
                ease: "power2",
                toggleActions: "play none none reverse"
            }
        });
        /*
        var social_icons_arr = gsap.utils.toArray(document.querySelectorAll('.social_box .cls-1'));
        social_icons_arr.forEach(function (el, i) {
            social_cameleon_tl.to(el, {fill:'#134a2c'});
        });*/
        social_cameleon_tl.to('.top_header .social_box .cls-1', {fill:'#134a2c'});
    }

    return {
        init: function(){
            hamburger();
            //img_caption();
           // paragraphs();
            slideshow();
            shrink_header();
            social_cameleon();
        }
    };
})(window);


if (window.addEventListener) {
    window.addEventListener("load", cssanimation.init, false);
}
else if (window.attachEvent) {
    window.attachEvent("onload", cssanimation.init);
}
else {
    window.onload = cssanimation.init;
}
