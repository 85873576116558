/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
  	  // JavaScript to be fired on all pages
      init: function() {
        $('.scroll_down_btn').click(function () {
      		var goto_target = $(this).data('goto');
      		goToByScroll(goto_target);
      	});
    	$('select').niceSelect();
    	if (get_viewport_width() <= 768) {
    	}
    	else {
    		/* Adding video */
    		if (document.getElementById('video_bg_holder')) {
    			var video_obj = document.createElement('video');
    			var video_container = document.getElementById('video_bg_holder');
    			$(video_container).find('> span.video_src').each(function (i, el) {
    				var video_src = $(el).data('src');
    				var video_type = $(el).data('type');
    				var video_src_el = document.createElement('source');
    				video_src_el.src = video_src;
    				video_src_el.setAttribute('type', 'video/' + video_type);
    				video_obj.append(video_src_el);
    				video_obj.style.display = 'block';
    			});
    			video_container.append(video_obj);
                video_obj.setAttribute('autoplay',true);
                video_obj.setAttribute('loop',true);
    		}
            set_main_content_container_width ();
            window.addEventListener('resize', function(event){
                set_main_content_container_width ();
            });
    	}
        $('#toggle_search').click(function () {
            $(this).parent().find('.search-form').slideToggle();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
      'woocommerce' : {
        init: function () {
            //$('.woocommerce-product-gallery__trigger').html('<span class="fa fa-search"></span>');
        }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function get_viewport_width() {
	/* https://andylangton.co.uk/blog/development/get-viewportwindow-size-width-and-height-javascript */
	var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],viewport_width=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	return viewport_width;
}


function goToByScroll(target){
	if (!$(target).length) return;
	var scrolltop_position = ('top' == target)?0:$(target).offset().top;
	scrolltop_position += -100;
	$('html,body').animate(
		{scrollTop: scrolltop_position},{
			duration: 1000,
			easing: 'swing'
		}
	);
}

function readCookie(name) {
    /* Thanks Vignesh Pichamani https://stackoverflow.com/questions/1458724/how-do-i-set-unset-a-cookie-with-jquery#answer-18652401 */
    var keyValue = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

function set_main_content_container_width () {
    /* Place the fixed header menu half its width from the left. Set the container width accordingly */

    var top_header_menu         = document.getElementById('top_header');
    var top_header_width        = top_header_menu.offsetWidth;
    var top_header_offset_left  = top_header_width + top_header_width/3*2;
    var main_content_overlap    = top_header_width/4;
    var main_container          = document.getElementById('content_holder');
    var main_container_width    = get_viewport_width() - top_header_offset_left + main_content_overlap;
    var main_container_raw_width = main_container_width; /* For backward compatibility */
    main_container_width += 'px';
    if (get_viewport_width() >= 1920) {
        top_header_menu.style.left = (main_container.offsetLeft - (top_header_width *2/3)) + 'px';
        var menu_box = document.getElementById('nav-primary');
        menu_box.style.width = (main_container_raw_width + top_header_width * .5) + 'px';
    }
    else {
        gsap.to (main_container, {
            width: main_container_width,
            duration: .5,
            ease: "power4.out",
            onComplete: function () {
                var menu_box = document.getElementById('nav-primary');
                menu_box.style.width = main_container_width;
            }
        }); /* Could be so cool to use animation at this, but alas computer says no. */
    }


}